import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PageTitle from "@/components/PageTitle";
import LoginTitle from "./LoginTitle";
import LoginPasswordForm from "./LoginPasswordForm";
import LoginVerifyCodeForm from "./LoginVerifyCodeForm";

interface Props {
  onBack: () => void;
  onLogin: () => void;
  gotoSignup: () => void;
}
export default function LoginForm(props: Props) {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("password");
  const handleTabChange = (event: SyntheticEvent, tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <PageTitle name="login" />
      <LoginTitle onBack={props.onBack}>{t("login.loginWithEmail")}</LoginTitle>
      <div className="w-full mx-auto">
        <TabContext value={activeTab}>
          <Box>
            <TabList
              value={activeTab}
              onChange={handleTabChange}
              sx={{
                "paddingLeft": 0,
                ".MuiTabs-flexContainer": {
                  justifyContent: "center",
                },
              }}
            >
              <Tab label={t("login.passwordLogin")} value="password" />
              <Tab label={t("login.verifyCodeLogin")} value="verifyCode" />
            </TabList>
          </Box>
          <TabPanel value="password" sx={{ padding: 0 }}>
            <LoginPasswordForm onLogin={props.onBack} gotoSignup={props.gotoSignup} />
          </TabPanel>
          <TabPanel value="verifyCode" sx={{ padding: 0 }}>
            <LoginVerifyCodeForm onLogin={props.onBack} gotoSignup={props.gotoSignup} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
}
