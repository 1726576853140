import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import PageTitle from "@/components/PageTitle";
import PartsMenu from "./PartsMenu";
import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow-left.svg";
import { ReactComponent as ArrowDownIcon } from "@/assets/icons/arrow-down-grey.svg";
import styles from "./PartEditNav.module.css";

interface Props {
  storyTitle?: string;
  story_id: number;
  part_id: number;
  hasUnsavedChanges?: boolean;
  isDelaySaving?: boolean; // 延迟保存中
  wordsCount?: number;
  partTitle?: string;
  isValid?: boolean;
  btnLock?: string;
  isPublished?: boolean;
  onPublish: () => void;
  onSave: () => void;
  onPreview: () => void;
  onCancel: () => void;
  onBack: () => void;
  onDelete: () => void;
}

export default function PartEditNav(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle name="myStories" />
      <header className={styles.header}>
        <div className={"content-wrap " + styles.inner}>
          <LoadingButton onClick={props.onBack} variant="text" color="info" className={styles.back}>
            <ArrowIcon />
          </LoadingButton>
          <PartsMenu story_id={props.story_id} active_part_id={props.part_id}>
            <div className={styles.meta}>
              {props.storyTitle || t("writing.defaultStoryTitle")}
              <ArrowDownIcon className="ml-2" />
            </div>
          </PartsMenu>
          <div className={styles.center}>
            <Typography variant="h3" component="h2" className={styles.title}>
              {props.partTitle || t("writing.defaultPartTitle")}{" "}
            </Typography>
            <div className="font-color-grey">
              <span className={props.isPublished ? "font-color-primary" : ""}>
                {props.isPublished ? t("common.published") : t("common.draft")}
              </span>
              <span>（{t("writing.writingCount", { count: props.wordsCount })}）</span>
              {props.isPublished && props.hasUnsavedChanges && (
                <span className="font-color-warn">{t("writing.unsavedChanges")}</span>
              )}
              {!props.isPublished && (
                <span className="font-color-primary ml-1">
                  {props.btnLock === "save" || props.isDelaySaving ? t("writing.saving") : t("writing.saved")}
                </span>
              )}
            </div>
          </div>
          <div className={styles.actions}>
            {!props.isPublished && (
              <LoadingButton
                variant="contained"
                color="primary"
                size="small"
                className={styles.btn}
                onClick={props.onPublish}
                disabled={!props.isValid || (!!props.btnLock && props.btnLock !== "publish")}
                loading={props.btnLock === "publish"}
              >
                {t("common.publish")}
              </LoadingButton>
            )}
            <LoadingButton
              variant={"contained"}
              color={props.isPublished ? "primary" : "info"}
              size="small"
              className={styles.btn}
              onClick={props.onSave}
              disabled={!!props.btnLock && props.btnLock !== "save"}
              loading={props.btnLock === "save"}
            >
              {props.isPublished ? t("writing.publishChanges") : t("common.save")}
            </LoadingButton>
            {!props.isPublished && (
              <LoadingButton
                variant="contained"
                color="info"
                size="small"
                className={styles.btn}
                onClick={props.onPreview}
                disabled={!!props.btnLock && props.btnLock !== "preview"}
                loading={props.btnLock === "preview"}
              >
                {t("common.preview")}
              </LoadingButton>
            )}
            {props.isPublished && (
              <LoadingButton
                variant="contained"
                color="info"
                size="small"
                className={styles.btn}
                onClick={props.onCancel}
                disabled={!!props.btnLock && props.btnLock !== "cancel"}
                loading={props.btnLock === "cancel"}
              >
                {t("common.cancel")}
              </LoadingButton>
            )}
            <LoadingButton
              variant="contained"
              color="info"
              size="small"
              className={styles.btn}
              onClick={props.onDelete}
              disabled={!!props.btnLock && props.btnLock !== "delete"}
              loading={props.btnLock === "delete"}
            >
              {t("common.delete")}
            </LoadingButton>
          </div>
        </div>
      </header>
    </>
  );
}
