/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import PageTitle from "@/components/PageTitle";
import { ReactComponent as RechargeSuccessIcon } from "@/assets/icons/recharge-success.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSearchParams } from "react-router-dom";

const ButtonCss = css`
  display: block;
  margin: 48px auto 0;
  width: 388px;
  height: 56px;
	padding: 0;
	line-height: 56px;
  font-size: 16px;
	background: rgba(0, 0, 0, 0.08);
	color: inherit;
	&:hover {
		background: rgba(0, 0, 0, 0.12);
		box-shadow: none;
	}
`;

export default function RechargeSuccess() {
  const { t } = useTranslation();
	const [searchParams] = useSearchParams();

	function handleRead() {
		window.location.href = searchParams.get('from')!;
	}

  return (
    <>
      <PageTitle name="rechargeSuccess" />
      <div
        className="content-wrap"
        css={css`
          padding-top: 100px;
          text-align: center;
        `}
      >
        <RechargeSuccessIcon className="mx-auto" />
        <Typography
          variant="body1"
          sx={{
            marginTop: "40px",
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 500,
						marginBottom: '64px'
          }}
        >
          {t("result.rechargeSuccess")}
        </Typography>
        <Button variant="contained" size="large" css={ButtonCss} onClick={
					handleRead
				}>
          {t("result.continueRead")}
        </Button>
        <Button variant="contained" size="large" css={ButtonCss} href="/">
          {t("result.backHome")}
        </Button>
      </div>
    </>
  );
}
