import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BlockTitle from "./BlockTitle";
import StoryListMain from "@/pages/StoryList/components/StoryListMain";
import { StoryEntity } from "@/entity/story";
import * as homeApi from "@/api/home";

export default function MaybeLike(props: { className?: string }) {
  const className = props.className || "";
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [items, setItems] = useState<StoryEntity[]>([]);

  const load = () => {
    homeApi.recommendedForYou().then((res) => {
      setItems(res.items);
    });
  };

  useEffect(() => {
    load();
  }, []);

	const viewAll = () => {
    navigate("/category");
	}

  return (
    <div className={className + " completed-stories"}>
      <BlockTitle divider actionText={t("home.viewAll")!} onActionClick={viewAll}>{t("home.youMayLike")}</BlockTitle>
      <StoryListMain className="mt-4" items={items} />
    </div>
  );
}
