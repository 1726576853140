import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Spinner from "@/components/Spinner";
import PageTitle from "@/components/PageTitle";
import StoryListAside from "./components/StoryListAside";
import StoryListHead from "./components/StoryListHead";
import StoryListMain from "./components/StoryListMain";
import * as readingApi from "@/api/reading";
import * as categoryApi from "@/api/category";
import { StoryOrder } from "@/config/enum";
import { StoryEntity } from "@/entity/story";
import { serializeQueryParams } from "@/utils/utils";

export default function Category() {
  const query = useParams();
  const [searchParams] = useSearchParams();
  const category_id = +query.category_id! || void 0;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState<string>("");
  const params = useMemo(
    () => ({
      page: +searchParams.get("page")! || 1,
      page_size: 10,
      order: (searchParams.get("order") as StoryOrder) || StoryOrder.UpdateTime,
      status: +searchParams.get("status")! || void 0,
    }),
    [searchParams]
  );
  const [state, setState] = useState({
    total: 0,
    items: [] as StoryEntity[],
  });

  const navigateWithParams = (updateParams: Partial<readingApi.StoryListParams>, new_category_id?: number | string) => {
    navigate(
      "/category/" +
        (new_category_id ?? category_id ?? "") +
        "?" +
        serializeQueryParams({
          ...params,
          ...updateParams,
          category_id: void 0,
          page_size: void 0,
        })
    );
  };

  const onCategoryChange = (category_id?: number) => {
    navigateWithParams({ page: 1 }, category_id);
  };

  const onPageChange = (page: number) => {
    navigateWithParams({ page });
  };

  useEffect(() => {
    const loadStories = async () => {
      setLoading(true);
      try {
        const data = await readingApi.storyList({
          ...params,
          category_id,
        });
        setState({
          ...data,
          total: data.total,
          items: data.items,
        });
      } finally {
        setLoading(false);
      }
    };
    loadStories();
  }, [params]);

  useEffect(() => {
    const loadCategoryInfo = async () => {
      const data = await categoryApi.categoryInfo({ category_id: category_id! });
      setCategoryName(data?.name);
    };
    if (category_id) {
      loadCategoryInfo();
    } else {
      setCategoryName("");
    }
  }, [category_id]);

  return (
    <>
      <PageTitle name="category" title={categoryName} />
      <Spinner loading={loading}>
        <div className="content-wrap flex justify-around pt-4">
          <StoryListAside value={category_id} onChange={onCategoryChange} />
          <div className="main flex-1 ml-8">
            <StoryListHead params={params} onParamsChange={(v) => navigateWithParams(v)} />
            <StoryListMain page={params.page!} page_size={params.page_size!} {...state} onPageChange={onPageChange} />
          </div>
        </div>
      </Spinner>
    </>
  );
}
