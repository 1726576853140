import Dialog from "@mui/material/Dialog";
import RechargeContent from "./RechargeContent";

interface Props {
  open: boolean;
  onClose?: () => void;
}
export default function RechargeDialog(props: Props) {
  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="md" sx={{
      ".MuiDialog-paper": {
        backgroundColor: "transparent",
      }
    }}>
      <RechargeContent />
    </Dialog>
  );
}
