import { StoryStatus, StoryOrder, RechargeMethod, SubscribeStoryStatus } from "@/config/enum";

export const copyRightOptions = [
  { value: 1, label: "All Rights Reserved", desc: "copyright.allRightsReserved" },
  { value: 2, label: "Public Domain", desc: "copyright.publicDomain"},
  { value: 3, label: "Creative Commons (CC) Attribution", desc: "copyright.ccAttribution" },
  { value: 4, label: "(CC) Attrib. NonCommercial", desc: "copyright.ccNonCommercial" },
  { value: 5, label: "(CC) Attrib. NonComm. NoDerivs", desc: "copyright.ccnNoDerivs" },
  { value: 6, label: "(CC) Attrib. NonComm. ShareAlike", desc: "copyright.ccnShareAlike" },
  { value: 7, label: "(CC) Attribution-ShareAlike", desc: "copyright.ccShareAlike" },
  { value: 8, label: "(CC) Attribution-NoDerivs", desc: "copyright.ccNoDerivs" },
];

const curYear = new Date().getFullYear();
export const yearOptions = Array.from({ length: 100 }, (v, k) => k + curYear - 100).map((year) => ({value: year, label: year}));

export const monthOptions = [
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" },
];

export const dayOptions = Array.from({ length: 31 }, (v, k) => k + 1).map((day) => ({value: day, label: day}));

export const storyStatusOptions = [
  {
    value: StoryStatus.Completed,
    label: "storyList.statusCompleted",
  },
  {
    value: StoryStatus.Ongoing,
    label: "storyList.statusOngoing",
  },
]

export const storyOrderOptions = [
  {
    value: StoryOrder.UpdateTime,
    label: "storyList.orderUpdateTime",
  },
  {
    value: StoryOrder.ReadCount,
    label: "storyList.orderViews",
  },
];

export const themeOptions = [
  {
    value: 'white',
    label: 'White',
    default: true,
    color: '#FFFFFF'
  },
  {
    value: 'beige',
    label: 'Beige',
    color: '#F5F0DD',
  },
  {
    value: 'gray',
    label: 'Gray',
    color: '#F2F2F2',
  },
  {
    value: 'dark',
    label: 'Dark',
    color: '#1C1C1D',
  }
]

export const fontSizeOptions = [
  {
    value: '14px',
    label: '14px',
  },
  {
    value: '16px',
    label: '16px',
  },
  {
    value: '18px',
    label: '18px',
    default: true,
  },
  {
    value: '24px',
    label: '24px',
  },
  {
    value: '28px',
    label: '28px',
  },
  {
    value: '32px',
    label: '32px',
  },
]

export const tags = [
  "Action",
  "Adventure",
  "Comedy",
  "Drama",
  "Fantasy",
  "Historical",
  "Horror",
  "Mystery",
  "Romance",
]

export {regionOptions} from './regions';

export const rechargeMethodOptions = [
  {
    value: RechargeMethod.Admin,
    label: "fund.rechargeMethodMap.admin",
  },
  {
    value: RechargeMethod.Stripe,
    label: "fund.rechargeMethodMap.stripe",
  },
  {
    value: RechargeMethod.GooglePlay,
    label: "fund.rechargeMethodMap.googlePlay",
  },
];

export const subscribeStatusOptions = [
  {
    value: SubscribeStoryStatus.On,
    label: "fund.subscribeStatusMap.On",
  },
  {
    value: SubscribeStoryStatus.Off,
    label: "fund.subscribeStatusMap.Off",
  },
];