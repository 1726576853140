/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "@/components/PageTitle";
import { ReactComponent as RechargeErrorIcon } from "@/assets/icons/recharge-error.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RechargeDialog from "@/components/Recharge/RechargeDialog";

const ButtonCss = css`
  display: block;
  margin: 48px auto 0;
  width: 388px;
  height: 56px;
	padding: 0;
	line-height: 56px;
  font-size: 16px;
	background: rgba(0, 0, 0, 0.08);
	color: inherit;
	&:hover {
		background: rgba(0, 0, 0, 0.12);
		box-shadow: none;
	}
`;

export default function RechargeError() {
  const { t } = useTranslation();
  const [rechargeOpen, setRechargeOpen] = useState(false);

  return (
    <>
      <PageTitle name="rechargeError" />
      <RechargeDialog open={rechargeOpen} onClose={() => setRechargeOpen(false)} />
      <div className="content-wrap" css={css`
				padding-top: 100px;
				text-align: center;
			`}>
        <RechargeErrorIcon className="mx-auto" />
        <Typography variant="body1" sx={{
            marginTop: "40px",
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 500,
						marginBottom: '24px'
          }}>{t("result.rechargeError")}</Typography>
				<Typography variant="body2" className="font-color-grey" sx={{
					marginBottom: '64px',
					fontSize: '16px',
					lineHeight: '24px',
				}}>{t("result.rechargeErrorTip")}</Typography>
				<Button variant="contained" size="large" css={ButtonCss} href="/">
          {t("result.backHome")}
        </Button>
				<Button variant="contained" size="large" css={ButtonCss} onClick={
					() => setRechargeOpen(true)
				}>
          {t("result.rechargeAgain")}
        </Button>
      </div>
    </>
  );
}
