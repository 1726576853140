import { MouseEvent, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as userApi from "@/api/user";
import { AuthContext } from "@/context";
import Spinner from "@/components/Spinner";
import Alert from "./Alert";
import LoginFooter from "./LoginFooter";

interface Props {
  gotoSignup: () => void;
  onLogin: () => void;
}
export default function LoginPasswordForm(props: Props) {
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = async (params: any) => {
    try {
      setLoading(true);
      const user = await userApi.login({
        email: params.email,
        password: params.password,
      });
      await setUser(user);
      props.onLogin();
    } catch (err: any) {
      setMessage(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spinner loading={loading}>
      <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Alert open={!!message} message={message} onClose={() => setMessage("")} className="mt-6 w-full" duration={3} />
        <div className="mt-6 w-full">
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("login.emailRequired") as string,
              pattern: { value: /^\S+@\S+$/i, message: t("login.emailInvalid") as string },
            }}
            render={({ field }) => (
              <FormControl error={!!errors.email} fullWidth>
                <OutlinedInput id="email" placeholder={t("login.email")!} {...field} sx={{ background: "#fff" }} />
                {errors.email && (
                  <FormHelperText error={!!errors.email}>{errors.email.message! as string}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </div>
        <div className="mt-6 w-full">
          <Controller
            name="password"
            control={control}
            rules={{
              required: t("login.passwordRequired") as string,
              minLength: { value: 8, message: t("login.passwordLengthMsg") as string },
              maxLength: { value: 20, message: t("login.passwordLengthMsg") as string },
            }}
            render={({ field }) => (
              <FormControl error={!!errors.password} fullWidth>
                <OutlinedInput
                  id="password"
                  placeholder={t("login.password")!}
                  sx={{
                    "background": "#fff",
                    "position": "relative",
                    "paddingRight": 0,
                    ".MuiInputBase-input": {
                      paddingRight: "40px",
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end" sx={{ position: "absolute", right: 0 }}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ margin: 0 }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...field}
                />
                {errors.password && (
                  <FormHelperText error={!!errors.password}>{errors.password.message! as string}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </div>
        <div className="mt-2 flex justify-between items-center font-color-grey">
          <FormControlLabel
            control={<Checkbox sx={{ color: "var(--border-color-highlight)" }} />}
            label={t("login.autoLogin")}
          />
          <a href="/login/forget">{t("login.forgotPassword")}</a>
        </div>
        <div className="mt-7">
          <Button type="submit" fullWidth variant="contained" /* disabled={!isValid} */ sx={{ height: "56px" }}>
            {t("common.login")}
          </Button>
        </div>
        <LoginFooter {...props} />
      </Box>
    </Spinner>
  );
}
