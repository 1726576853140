import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BlockTitle from "./BlockTitle";
import NewArrivalsSliders from "./NewArrivalsSliders";
import { StoryEntity } from "@/entity/story";
import * as homeApi from "@/api/home";

export default function NewArrivals() {
  const { t } = useTranslation();
  const [items, setItems] = useState<StoryEntity[]>([]);

  useEffect(() => {
    homeApi.newStories().then((res) => {
      setItems(res.items);
    });
  }, []);

  return (
    <div className="new-arrivals">
      <BlockTitle>{t("home.newArrivals")}</BlockTitle>
      <NewArrivalsSliders items={items} />
    </div>
  );
}