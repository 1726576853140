import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import FooterText from "./FooterText";

interface Props {
  gotoLogin: () => void;
}
export default function SignupFooter(props: Props) {
  const { t } = useTranslation();

  const handleClick = (evt: MouseEvent) => {
    evt.preventDefault();
    props.gotoLogin();
  }

  return (
    <>
      <div className="text-center" style={{ marginTop: "130px" }}>
        <a href="/login" className="font-color-primary" onClick={handleClick}>
          <span className="font-color-grey mr-2">{t("login.alreadyHasAccount")}</span>
          {t("login.loginNow")}
        </a>
      </div>
      <FooterText />
    </>
  );
}
