import { MouseEvent, useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Turnstile, { useTurnstile } from "react-turnstile";
import config from "@/config/config";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  // MenuItem,
  OutlinedInput,
  // Select,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "./Alert";
import * as userApi from "@/api/user";
import { AuthContext } from "@/context";
// import { yearOptions, monthOptions, dayOptions } from "@/config/options";
import PageTitle from "@/components/PageTitle";
import LoginTitle from "./LoginTitle";
import Spinner from "@/components/Spinner";

interface Props {
  onBack: () => void;
  onSignup: () => void;
}
export default function SignupForm(props: Props) {
  const { t, i18n } = useTranslation();
  const { setUser } = useContext(AuthContext);

  const [cachedUser, setCachedUser] = useState<any>(null);
  const handleBackHome = () => {
    if (cachedUser) {
      setUser(cachedUser);
    }
    window.location.href = "/";
  };

  const turnstile = useTurnstile();
  const [turnstileToken, setTurnstileToken] = useState("");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const timer = useRef<number | null>(null);
  const [countdown, setCountdown] = useState(0);
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      verifyCode: "",
      password: "",
      repassword: "",
      username: "",
      // birthYear: void 0,
      // birthMonth: void 0,
      // birthDay: void 0,
    },
  });

  const onSubmit = async (params: any) => {
    try {
      setLoading(true);
      const user = await userApi.signup({
        email: params.email,
        password: params.password,
        verifyCode: params.verifyCode,
        username: params.username,
        // birth_year: params.birthYear,
        // birth_month: params.birthMonth,
        // birth_day: params.birthDay,
        lang: i18n.language,
      });
      setSuccess(true);
      setCachedUser(user);
    } catch (err: any) {
      setMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const stopCountdownTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const startCountdownTimer = () => {
    stopCountdownTimer();
    setCountdown(60);
    timer.current = window.setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          stopCountdownTimer();
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1e3);
  };

  const handleVerifyCode = async () => {
    const email = getValues("email");
    if (!turnstileToken) {
      setMessage(t("login.turnstileRequired")!);
      return;
    }

    if (email && !errors.email) {
      startCountdownTimer();
      await userApi.sendSignupVerifyCode({
        email,
        turnstileToken,
      });
      turnstile.reset();
    }
  };

  useEffect(() => {
    return () => {
      stopCountdownTimer();
    };
  }, []);

  return (
    <>
      <PageTitle name="signup" />
      <Spinner loading={loading}>
        <div>
          <LoginTitle onBack={props.onBack}>{t("common.signup")}</LoginTitle>
          {success ? (
            <div className="text-center py-12">
              <CheckCircleIcon color="primary" sx={{ fontSize: "72px" }} />
              <Typography
                variant="body1"
                sx={{ fontSize: "18px", lineHeight: "1.5", fontWeight: 500, marginTop: "2rem" }}
              >
                {t("login.signupSuccessP1")}
                <br />
                <span className="text-base">{getValues("email")}</span>
                {t("login.signupSuccessP2")}
              </Typography>
              <div className="mt-14">
                <Button
                  onClick={handleBackHome}
                  variant="light"
                  sx={{
                    height: "40px",
                  }}
                >
                  {t("login.backToHome")}
                </Button>
              </div>
            </div>
          ) : (
            <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Alert
                open={!!message}
                message={message}
                onClose={() => setMessage("")}
                className="mt-6 w-full"
                duration={3}
              />
              <div className="mt-6 w-full">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: t("login.emailRequired") as string,
                    pattern: { value: /^\S+@\S+$/i, message: t("login.emailInvalid") as string },
                  }}
                  render={({ field }) => (
                    <FormControl error={!!errors.email} fullWidth>
                      <OutlinedInput
                        id="email"
                        placeholder={t("login.email")!}
                        sx={{ background: "#fff" }}
                        {...field}
                      />
                      {errors.email && (
                        <FormHelperText error={!!errors.email}>{errors.email.message! as string}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>
              <div className="mt-6 flex">
                <Turnstile
                  sitekey={config.turnstileSiteKey}
                  css={{ transform: "scale(0.96)", transformOrigin: "left" }}
                  onVerify={(token) => {
                    setTurnstileToken(token);
                  }}
                />
              </div>
              <div className="mt-6 w-full flex justify-between">
                <Controller
                  name="verifyCode"
                  control={control}
                  rules={{
                    required: t("login.verifyCodeRequired") as string,
                  }}
                  render={({ field }) => (
                    <FormControl error={!!errors.verifyCode} className="flex-1">
                      <OutlinedInput
                        id="verifyCode"
                        placeholder={t("login.verifyCode")!}
                        sx={{ background: "#fff" }}
                        {...field}
                      />
                      {errors.verifyCode && (
                        <FormHelperText error={!!errors.verifyCode}>
                          {errors.verifyCode.message! as string}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
                <div>
                  <Button
                    variant={!getValues("email") || !!errors.email || !!countdown ? "light" : "contained"}
                    sx={{
                      marginLeft: "1.5rem",
                      height: "56px",
                      width: "128px",
                    }}
                    disabled={!getValues("email") || !!errors.email || !!countdown}
                    onClick={handleVerifyCode}
                  >
                    {countdown ? countdown + "S" : t("login.getVerifyCode")}
                  </Button>
                </div>
              </div>
              <div className="mt-6 w-full">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: t("login.passwordRequired") as string,
                    minLength: { value: 8, message: t("login.passwordLengthMsg") as string },
                    maxLength: { value: 20, message: t("login.passwordLengthMsg") as string },
                  }}
                  render={({ field }) => (
                    <FormControl error={!!errors.password} fullWidth>
                      <OutlinedInput
                        id="password"
                        sx={{ background: "#fff" }}
                        placeholder={t("login.password")!}
                        {...field}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: "var(--font-color-lightgrey)" }}
                              tabIndex={-1}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.password && (
                        <FormHelperText error={!!errors.password}>{errors.password.message! as string}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>
              <div className="mt-6 w-full">
                <Controller
                  name="repassword"
                  control={control}
                  rules={{
                    required: t("login.confirmPasswordRequired") as string,
                    minLength: { value: 8, message: t("login.confirmPasswordLengthMsg") as string },
                    maxLength: { value: 20, message: t("login.confirmPasswordLengthMsg") as string },
                    validate: (value, formValues) => {
                      return value === formValues.password || (t("login.passwordNotMatch") as string);
                    },
                  }}
                  render={({ field }) => (
                    <FormControl error={!!errors.repassword} fullWidth>
                      <OutlinedInput
                        id="repassword"
                        sx={{ background: "#fff" }}
                        placeholder={t("login.confirmPassword")!}
                        {...field}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              sx={{ color: "var(--font-color-lightgrey)" }}
                              tabIndex={-1}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.repassword && (
                        <FormHelperText error={!!errors.repassword}>
                          {errors.repassword.message! as string}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>
              <div className="mt-6 w-full">
                <Controller
                  name="username"
                  control={control}
                  // rules={{
                  //   required: t("login.usernameRequired") as string,
                  //   minLength: { value: 3, message: t("login.usernameLengthMsg") as string },
                  //   maxLength: { value: 30, message: t("login.usernameLengthMsg") as string },
                  // }}
                  render={({ field }) => (
                    <FormControl error={!!errors.username} fullWidth>
                      <OutlinedInput
                        id="username"
                        placeholder={t("login.username")!}
                        sx={{ background: "#fff" }}
                        {...field}
                      />
                      {errors.username && (
                        <FormHelperText error={!!errors.username}>{errors.username.message! as string}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </div>
              {/* <div className="mt-6 w-full">
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1rem", lineHeight: "1.5rem", fontWeight: 400, marginBottom: "0.5rem" }}
                >
                  {t("login.birthday")}
                </Typography>
                <div className="flex flex-row relative">
                  <FormControl fullWidth>
                    <Controller
                      name="birthYear"
                      control={control}
                      rules={{ required: t("login.birthYearRequired") as string }}
                      render={({ field }) => (
                        <Select
                          id="birthYear"
                          displayEmpty
                          className="flex-1 mr-5"
                          sx={{ background: "#fff" }}
                          {...field}
                          renderValue={(selected: number) => {
                            if (!selected) {
                              return <Typography color={"darkgray"}>{t("login.year")}</Typography>;
                            }
                            return yearOptions.find((item) => item.value === selected)?.label ?? "";
                          }}
                        >
                          {yearOptions.map((cp) => (
                            <MenuItem key={cp.value} value={cp.value}>
                              {cp.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Controller
                      name="birthMonth"
                      control={control}
                      rules={{ required: t("login.birthMonthRequired") as string }}
                      render={({ field }) => (
                        <Select
                          id="birthMonth"
                          displayEmpty
                          className="flex-1 mr-5"
                          sx={{ background: "#fff" }}
                          {...field}
                          renderValue={(selected: number) => {
                            if (!selected) {
                              return <Typography color={"darkgray"}>{t("login.month")}</Typography>;
                            }
                            return monthOptions.find((item) => item.value === selected)?.label ?? "";
                          }}
                        >
                          {monthOptions.map((cp) => (
                            <MenuItem key={cp.value} value={cp.value}>
                              {cp.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Controller
                      name="birthDay"
                      control={control}
                      rules={{ required: t("login.birthDayRequired") as string }}
                      render={({ field }) => (
                        <Select
                          id="birthDay"
                          displayEmpty
                          className="flex-1"
                          sx={{ background: "#fff" }}
                          {...field}
                          renderValue={(selected: number) => {
                            if (!selected) {
                              return <Typography color={"darkgray"}>{t("login.day")}</Typography>;
                            }
                            return dayOptions.find((item) => item.value === selected)?.label ?? "";
                          }}
                        >
                          {dayOptions.map((cp) => (
                            <MenuItem key={cp.value} value={cp.value}>
                              {cp.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  {(errors.birthYear || errors.birthMonth || errors.birthDay) && (
                    <FormHelperText error={!!(errors.birthYear || errors.birthMonth || errors.birthDay)}>
                      {(errors.birthYear || errors.birthMonth || errors.birthDay)?.message!}
                    </FormHelperText>
                  )}
                </div>
              </div> */}
              <div className="mt-6">
                <Button type="submit" fullWidth variant="contained" sx={{ height: "56px" }}>
                  {t("common.signup")}
                </Button>
              </div>
            </Box>
          )}
        </div>
      </Spinner>
    </>
  );
}
