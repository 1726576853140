/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { StoryEntity } from "@/entity/story";
import AddToLibrary from "@/components/AddToLibrary";
import { formatCover } from "@/utils/format";

interface Props {
  items: Partial<StoryEntity>[];
}
export default function NewArrivalsSliders(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { items } = props;
  const [active, setActive] = useState(0);
  const activeItem = items[active] || items[0];

  const handleClick = (item: Partial<StoryEntity>) => {
    navigate(`/story/${item.id}`);
  };

  const changeActive = (index: number) => {
    setActive(index);
  };

  return (
    <div
      className="bg-color-default"
      css={css`
        width: 582px;
        height: 280px;
        position: relative;
        overflow: hidden;
        padding: 16px;
        border-radius: 5px;
      `}
    >
      <ul
        className="clearfix"
        css={css`
          margin-left: -4px;
          margin-right: -4px;
          margin-bottom: 16px;
        `}
      >
        {props.items.map((item, index) => (
          <li
            onClick={() => changeActive(index)}
            key={item.id}
            className="flex mx-1 relative float-left cursor-pointer"
            css={css`
              &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-sizing: border-box;
                border-radius: 4px;
                border: 3px solid var(--primary-color);
                box-shadow: inset 0 0 0 1px #fff;
                visibility: hidden;
                opacity: 0;
                transition: 0.2s;
                ${item.id === activeItem?.id
                  ? css`
                      visibility: visible;
                      opacity: 1;
                    `
                  : ""};
              }
            `}
          >
            <div
              css={css`
                width: 54px;
                height: 72px;
                border-radius: 4px;
                overflow: hidden;
                &:hover img {
                  transform: scale(1.1);
                }
              `}
            >
              <img
                {...formatCover(item.cover)}
                alt={item.title}
                loading="lazy"
                css={css`
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  transition: transform 0.3s ease-out;
                `}
              />
            </div>
          </li>
        ))}
      </ul>
      <div className="flex">
        <Link
          className="block"
          to={`/story/${activeItem?.id}`}
          css={css`
            display: block;
            width: 120px;
            height: 160px;
            border-radius: 4px;
            overflow: hidden;
            &:hover img {
              transform: scale(1.1);
            }
          `}
        >
          <img
            {...formatCover(activeItem?.cover)}
            alt={activeItem?.title}
            loading="lazy"
            css={css`
              object-fit: cover;
              width: 100%;
              height: 100%;
              transition: transform 0.3s ease-out;
            `}
          />
        </Link>
        <div className="flex-1 ml-4 min-w-0">
          <Link className="block" to={`/story/${activeItem?.id}`}>
            <Typography
              variant="h4"
              onClick={() => handleClick(activeItem)}
              className="truncate"
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: 1.45,
              }}
            >
              {activeItem?.title}
            </Typography>
          </Link>
          <div className="mt-1 mb-2">
            <Link
              to={`/category/${activeItem?.category?.id}`}
              className="font-color-grey"
              css={css`
                font-size: 12px;
              `}
            >
              {activeItem?.category?.name}
            </Link>
          </div>
          <div className="flex items-center">
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleClick(activeItem)}
              sx={{
                borderRadius: "20px",
                fontSize: "12px",
                height: "32px",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
            >
              {t("home.readNow")}
            </Button>
            <AddToLibrary story_id={activeItem?.id!}>
              <span
                className="ml-2 flex justify-center items-center font-color-primary cursor-pointer w-8 h-8 rounded-2xl"
                css={css`
                  background: #dbfdff;
                  &:hover {
                    background: #b4f2ff;
                  }
                `}
              >
                <AddIcon />
              </span>
            </AddToLibrary>
          </div>
        </div>
      </div>
    </div>
  );
}
