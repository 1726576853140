/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import BlockTitle from "./BlockTitle";
import { TagEntity } from "@/entity/tag";
import * as homeApi from "@/api/home";

export default function PopularTags() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [items, setItems] = useState<TagEntity[]>([]);

  useEffect(() => {
    homeApi.popularTags().then((res) => {
      setItems(res.items);
    });
  }, []);

  const handleMore = () => {
    navigate("/tag");
  };

  return (
    <div className="hotStory">
      <BlockTitle divider actionText={t("common.more")!} onActionClick={handleMore}>
        {t("home.hotTags")}
      </BlockTitle>
      <ul
        className="clearfix"
        css={css`
          margin-left: -24px;
        `}
      >
        {items.map(item => (
          <li key={item.id} className="mt-6 float-left ml-6">
            <Link
              className="block font-color-primary"
              css={css`
                padding: 13.5px 16px 13.5px;
                background: #fff;
                border-radius: 5px;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
              `}
              to={`/tag/${item.id}`}
              key={item.id}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
