import { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, Button } from "@mui/material";
import { useRouteMatch } from "@/hooks";
import { AuthContext } from "@/context";
import * as fundApi from "@/api/fund";
import Spinner from "@/components/Spinner";
import RechargeDialog from "@/components/Recharge/RechargeDialog";
import { ReactComponent as BCurrencyIcon } from "@/assets/icons/b-currency.svg";

export default function Fund() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const routeMatch = useRouteMatch(["/fund/recharge", "/fund/purchase", "/fund/subscribe"]);
  const currentTab = routeMatch?.pattern?.path;
  const { user } = useContext(AuthContext);
  const [tokens, setTokens] = useState(user?.tokens || 0);
  const [points, setPoints] = useState(user?.points || 0);
  const [rechargeOpen, setRechargeOpen] = useState(false);

  
  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const balance = await fundApi.getBalance();
        setTokens(balance.tokens);
        setPoints(balance.points);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);

  return (
    <div className="content-wrap pt-6">
      <RechargeDialog open={rechargeOpen} onClose={() => setRechargeOpen(false)} />
      <Spinner loading={loading} horizontal>
        <div className="bg-color-default py-[20px] px-[32px] flex justify-between items-center mb-2 rounded">
          <div className="flex items-center">
            <BCurrencyIcon className="inline-block w-8 h-8 mr-4" />
            <strong className="text-4xl">{tokens} {t("common.tokens")}</strong>
            <strong className="text-2xl ml-4 mt-2">{points} {t("common.points")}</strong>
          </div>
          <Button variant="contained" color="primary" sx={{ padding: "7px 32px", fontWeight: 500 }} onClick={() => setRechargeOpen(true)}>
            {t("fund.rechargeBtn")}
          </Button>
        </div>
      </Spinner>
      <Tabs value={currentTab} sx={{ paddingLeft: 0 }}>
        <Tab
          label={t("fund.rechargeRecords")}
          value="/fund/recharge"
          to="/fund/recharge"
          component={Link}
          sx={{ marginLeft: 0, marginRight: "35px" }}
        />
        <Tab
          label={t("fund.purchaseRecords")}
          value="/fund/purchase"
          to="/fund/purchase"
          component={Link}
          sx={{ marginRight: "35px" }}
        />
        <Tab label={t("fund.subscribeRecords")} value="/fund/subscribe" to="/fund/subscribe" component={Link} />
      </Tabs>
      <div className="mt-2 relative">
        <Outlet />
      </div>
    </div>
  );
}
