import { createTheme } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    light: true;
  }

  interface ButtonPropsSizeOverrides {
    mini: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      light: "#7DDDE1",
      main: "#13C5CE",
      dark: "#00BAC8",
      contrastText: "#FFF",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.85)",
      secondary: "#868C92",
    },
    secondary: {
      light: "rgba(237, 66, 75, 0.2)",
      main: "rgba(237, 66, 75, 0.1)",
      dark: "rgba(237, 66, 75, 1)",
      contrastText: "#FFF",
    },
    info: {
      light: "#F5F7F8",
      main: "#E7E7E7",
      dark: "#E1E1E1",
    },
    divider: "rgba(0, 0, 0, 0.08)",
  },
  typography: {
    button: {
      textTransform: "none",
    },
    caption: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "14px",
          fontFamily:
            "'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
          color: "var(--font-color-default)",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          fontWeight: 400,
          fontSize: "15px",
        },
        startIcon: {
          marginRight: "3px",
        },
      },
      variants: [
        {
          props: { variant: "light" },
          style: {
            "background": "#fff",
            "border": "1px solid var(--border-color-highlight)",
            "color": "var(--font-color-grey)",
            "fontWeight": 400,
            "&:hover": {
              color: "var(--primary-color)",
              background: "#fff",
              borderColor: "var(--primary-color)",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            "color": "rgba(237, 66, 75, 1)",
            "&:hover": {
              background: "rgba(237, 66, 75, 0.25)",
            },
            "&.selected": {
              background: "rgba(237, 66, 75, 1)",
              color: "#fff",
            },
          },
        },
        {
          props: { variant: "contained", color: "info" },
          style: {
            color: "var(--font-color-default)",
          },
        },
        {
          props: { variant: "outlined", color: "info" },
          style: {
            color: "var(--font-color-normal)",
            borderColor: "var(--border-color-highlight)",
          },
        },
        {
          props: { size: "mini" },
          style: {
            padding: "2px 12px",
            lineHeight: "1.5rem",
            fontSize: "12px",
            minWidth: "40px",
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        sizeSmall: {
          padding: '5px 16px',
          height: '32px',
          lineHeight: '22px',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          background: "#E7F9FA",
          color: "var(--primary-color)",
          cursor: "pointer",
          ".MuiChip-deleteIcon": {
            color: "var(--primary-color)",
            "&:hover": {
              color: "var(--primary-color)",
            }
          }
        },
        sizeMedium: {
          "height": "34px",
          "borderRadius": "17px",
          ".MuiChip-label": {
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        },
        sizeSmall: {
          fontSize: "12px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          paddingLeft: "0.8rem",
        },
        indicator: {
          height: "4px",
          borderRadius: "2px",
          bottom: "10px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "marginLeft": "24px",
          "marginRight": "24px",
          "fontSize": "18px",
          "lineHeight": "2.5rem",
          "color": "rgba(0, 0, 0, 0.25)",
          "fontWeight": 500,
          "paddingLeft": "0px",
          "paddingRight": "0px",
          "paddingTop": "16px",
          "paddingBottom": "16px",
          "minWidth": "40px",
          "&.Mui-selected": {
            color: "var(--font-color-default)",
            fontWeight: 500,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "var(--font-color-normal)",
          "&.Mui-selected": {
            background: "none",
            color: "var(--primary-color)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "color": "var(--font-color-default)",
          "fontSize": "1rem",
          "borderColor": "var(--border-color-highlight)",
          "fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--border-color-highlight)",
          },
          "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--primary-color)",
          },
          "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "var(--font-color-default)",
          fontSize: "1.125rem",
          marginBottom: "0.5rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          top: "100%",
          left: 0,
          fontSize: "12px",
          lineHeight: 1,
          marginLeft: "3px",
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: 70,
          height: 40,
          padding: 0,
          '& .MuiSwitch-track': {
            width: '100%',
            height: '100%',
            opacity: 1,
            border: 0,
            borderRadius: '2px',
            backgroundColor: '#C9CDD4',
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 32,
            height: 32,
            borderRadius: 0,
          },
          ".MuiSwitch-switchBase": {
            padding: 0,
            margin: 4,
            '&.Mui-checked': {
              transform: 'translateX(30px)',
              color: '#fff',
              borderRadius: 0,
              '& + .MuiSwitch-track': {
                backgroundColor: 'var(--primary-color)',
                opacity: 1,
              },
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              opacity: 0.7,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.7,
            },
          },
         
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(0, 0, 0, 0.08)",
          borderBottom: "none",
          borderRadius: "4px",
          borderCollapse: "separate",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "borderColor": "rgba(0, 0, 0, 0.08)",
          "lineHeight": "21px",
          "paddingLeft": "24px",
          "paddingRight": "24px",
          // paddingLeft: 0
          "&.MuiTableCell-head": {
            background: "#F5F7F8",
            "lineHeight": "24px",
            color: "rgba(0, 0, 0, 0.65)",
          },
        },
        sizeSmall: {
          "padding": "8px",
          "&:first-of-type": {
            "paddingLeft": "16px",
          },
          "&:last-of-type": {
            "paddingRight": "16px",
          },
          "&.MuiTableCell-head": {
            "paddingTop": "16px",
            "paddingBottom": "16px"
          }
        }
      },
    },
  },
});
