import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BlockTitle from "./BlockTitle";
import { StoryEntity } from "@/entity/story";
import FeaturedThisWeekSlider from "./FeaturedThisWeekSlider";
import * as homeApi from "@/api/home";

export default function FeaturedThisWeek() {
  const { t } = useTranslation();
  const [items, setItems] = useState<StoryEntity[]>([]);

  useEffect(() => {
    homeApi.featuredThisWeek().then((res) => {
      setItems(res.items);
    });
  }, []);

  return (
    <div className="this-week">
      <BlockTitle>{t("home.thisWeekRecommend")}</BlockTitle>
      <FeaturedThisWeekSlider items={items} />
    </div>
  );
}
