import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TrendingStoriesList from "./TrendingStoriesList";
import BlockTitle from "./BlockTitle";
import { StoryEntity } from "@/entity/story";
import * as homeApi from "@/api/home";

export default function HotStory() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [items1, setItems1] = useState<StoryEntity[]>([]);
  const [items2, setItems2] = useState<StoryEntity[]>([]);
  const [items3, setItems3] = useState<StoryEntity[]>([]);

  useEffect(() => {
    homeApi.powerRankings().then((res) => {
      setItems1(res.items);
    });
    homeApi.topCollections().then((res) => {
      setItems2(res.items);
    });
    homeApi.activeWriters().then((res) => {
      setItems3(res.items);
    });
  }, []);

	const handleMore = () => {
    navigate("/category");
	}

  return (
    <div className="hotStory">
      <BlockTitle divider actionText={t("common.more")!} onActionClick={handleMore}>{t("home.hotStory")}</BlockTitle>
			<div className="flex mt-6">
				<TrendingStoriesList className="flex-1 mr-10 min-w-0 overflow-hidden" key="powerRanking" title={t("home.powerRanking")} items={items1} />
				<TrendingStoriesList className="flex-1 mr-10 min-w-0 overflow-hidden" key="collectionRanking" title={t("home.collectionRanking")} items={items2} />
				<TrendingStoriesList className="flex-1 min-w-0 overflow-hidden" key="activeRanking" title={t("home.activeRanking")} items={items3} />
			</div>
    </div>
  );
}
