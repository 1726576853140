import { StoryEntity } from "@/entity/story";
import { TagEntity } from "@/entity/tag";
import $http from "@/utils/http";

export interface ListParams {
  limit?: number;
}

export function featuredThisWeek(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/featuredThisWeek",
    method: "get",
    params,
  });
}

export function weeklyRecommendations(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/weeklyRecommendations",
    method: "get",
    params,
  });
}

export function powerRankings(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/powerRankings",
    method: "get",
    params,
  });
}

export function topCollections(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/topCollections",
    method: "get",
    params,
  });
}

export function activeWriters(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/activeWriters",
    method: "get",
    params,
  });
}

export function popularTags(params?: ListParams): Promise<{ items: TagEntity[] }> {
  return $http.request({
    url: "home/popularTags",
    method: "get",
    params,
  });
}

export function newStories(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/newStories",
    method: "get",
    params,
  });
}

export function editorsSelection(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/editorsSelection",
    method: "get",
    params,
  });
}

export function finishedStories(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/finishedStories",
    method: "get",
    params,
  });
}

export function recommendedForYou(params?: ListParams): Promise<{ items: StoryEntity[] }> {
  return $http.request({
    url: "home/recommendedForYou",
    method: "get",
    params,
  });
}
