import React from "react";
import { PrivateUserEntity } from "@/entity/user";

export const AuthContext = React.createContext({
	user: null as null | PrivateUserEntity,
	isLogin: false as boolean,
	setUser: (u: null | PrivateUserEntity) => void 0 as void,
	logout: () => void 0 as void,
});

export const GlobalDialogContext = React.createContext({
	loginOpen: false as boolean,
	setLoginOpen: (open: boolean) => void 0 as void,
	signupOpen: false as boolean,
	setSignupOpen: (open: boolean) => void 0 as void,
});