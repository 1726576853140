/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import BlockTitle from "./BlockTitle";
import WeeklyRecommendationsSlider from "./WeeklyRecommendationsSlider";
import { formatCover } from "@/utils/format";
import { StoryEntity } from "@/entity/story";
import * as homeApi from "@/api/home";

export default function WeeklyRecommendations() {
  const { t } = useTranslation();
  const [items, setItems] = useState<StoryEntity[]>([]);

  useEffect(() => {
    homeApi.weeklyRecommendations().then((res) => {
      setItems(res.items);
    });
  }, []);

  return (
    <div className="this-week">
      <BlockTitle>{t("home.everyWeekRecommend")}</BlockTitle>
      <div className="flex">
        <WeeklyRecommendationsSlider items={items.slice(0, 3)} />
        <ul
          className="clearfix flex-1 min-w-0"
  
        >
          {items.slice(3).map((item, index) => (
            <li
              key={item.id}
              css={css`
                float: left;
                width: 110px;
                height: 221px;
                margin-left: 24px;
                margin-top: ${index >= 6 ? '26px' : 0}
              `}
            >
              <Link to={`/story/${item.id}`} css={css`
                display: block;
                width: 110px;
                height: 147px;
                border-radius: 5px;
                overflow: hidden;
                &:hover img {
                  transform: scale(1.1);
                }
              `}>
                <img
                  {...formatCover(item.cover)}
                  alt={item.title}
                  loading="lazy"
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transition: transform 0.3s ease-out;
                  `}
                />
              </Link>

              <Link to={`/story/${item.id}`}>
                <Typography
                  variant="h4"
                  className="line-clamp-2"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 500,
                    marginTop: "8px",
                    marginBottom: "6px",
                  }}
                >
                  {item.title}
                </Typography>
              </Link>
              <Link to={`/category/${item.category?.id}`}>
                <Typography variant="body2" className="truncate">{item.category?.name}</Typography>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
