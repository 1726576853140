import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as fundApi from "@/api/fund";
import { RechargeMethod } from "@/config/enum";
import { AuthContext, GlobalDialogContext } from "@/context";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import UserAvatar from "@/components/UserAvatar";
import Spinner from "@/components/Spinner";
import { ReactComponent as HotIcon } from "@/assets/icons/hot-24.svg";
import { ReactComponent as TokensIcon } from "@/assets/icons/tokens.svg";
import { ReactComponent as PointsIcon } from "@/assets/icons/points.svg";
import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow-right.svg";
import StripeIconImage from "@/assets/images/stripe@2x.png";
import styles from "./RechargeContent.module.css";

interface Props {
  className?: string;
}
export default function RechargeContent({ className }: Props) {
  const { t } = useTranslation();
  const { isLogin, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { setLoginOpen } = useContext(GlobalDialogContext);
  const [tokens, setTokens] = useState(user?.tokens || "-");
  const [points, setPoints] = useState(user?.points || "-");
  const methods = [
    {
      key: RechargeMethod.Stripe,
      title: t("fund.rechargeMethodMap.stripe"),
      icon: StripeIconImage,
    },
  ];
  const [products, setProducts] = useState<fundApi.RechargeProduct[]>([]);
  const [activeMethodKey, setActiveMethodKey] = useState<string | null>(methods[0]?.key);
  const [activeProductKey, setActiveProductKey] = useState<string | null>(null);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const balance = await fundApi.getBalance();
        setTokens(balance.tokens);
        setPoints(balance.points);
      } finally {
        setLoading(false);
      }
    };

    if (isLogin) {
      load();
    } else {
      setLoginOpen(true);
    }
  }, [isLogin]);

  const activeProduct = products.find((item) => item.key === activeProductKey);

  function handlePay() {
    if (!activeProductKey) {
      return;
    }
    if (!activeProduct) {
      return;
    }
    if (!activeMethodKey) {
      return;
    }

    switch (activeMethodKey) {
      case RechargeMethod.Stripe:
        window.location.href = fundApi.getStripeRechargeUrl({
          product_key: activeProductKey,
          from: window.location.href,
        });
        break;
    }
  }

  useEffect(() => {
    const load = async () => {
      const ret = await fundApi.getRechargeProducts();
      setProducts(ret.products);
      setActiveProductKey(ret.products.find((item) => item.hot)?.key || ret.products[0]?.key || null);
    };
    load();
  }, []);

  return (
    <Spinner loading={loading} horizontal>
      <div className={styles.container + " " + (className || "")}>
        <div className={styles.userinfoWrap}>
          <div className={styles.avatar}>{user && <UserAvatar user={user!} size="s" />}</div>
          <div className={styles.userinfo}>
            <Link className={styles.link} to="/fund/recharge">
              {t("fund.storeTransHistory")}
              <ArrowIcon />
            </Link>
            <div className={styles.balance}>
              <div className={styles.tokens}>
                <div>{tokens}</div>
                <div className={styles.label}>{t("common.tokens")}</div>
              </div>
              <div className={styles.points}>
                <div>{points}</div>
                <div className={styles.label}>{t("common.points")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.desc}>{t("fund.rechargeConversionDesc")}</div>
            <div className={styles.productList}>
              {products.map((product) => (
                <div
                  className={styles.productItem + " " + (product.key === activeProductKey ? "active" : "")}
                  key={product.key}
                  onClick={() => setActiveProductKey(product.key)}
                >
                  <div className={styles.productBody}>
                    <div className={styles.productTitle}>
                      <strong>{product.tokens}</strong> {t("common.tokens")} <TokensIcon />
                    </div>
                    <div className={styles.productsGift}>
                      +{product.gift_points || 0} {t("common.points")}
                      <PointsIcon />
                    </div>
                  </div>
                  <div className={styles.productFooter}>
                    <div className={styles.productPrice}>
                      <span className="mr-1">{product.currency}</span>
                      {product.amount.toFixed(2)}
                    </div>
                  </div>
                  {product.gift_points > 0 && (
                    <div className={styles.productCorner}>
                      {product.hot && <HotIcon className={styles.productHot} />}+
                      {parseFloat(((product.gift_points * 100) / product.tokens).toFixed(1))}%
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className={styles.methodBox}>
              <Typography variant="h5" className={styles.methodTitle}>
                {t("fund.paymentMethod")}
              </Typography>
              <div className={styles.methodList}>
                {methods.map((method) => (
                  <div
                    className={styles.methodItem + " " + (method.key === activeMethodKey ? "active" : "")}
                    key={method.key}
                    title={method.title}
                    onClick={() => setActiveMethodKey(method.key)}
                  >
                    <img src={method.icon} alt={method.title} style={{ width: "64px", height: "24px" }} />
                  </div>
                ))}
              </div>
            </div>
            {activeProduct && (
              <div className={styles.settleBox}>
                <div className={styles.settleContent}>
                  <div className={styles.settleInfo}>
                    <div className={styles.settlePrice}>
                      <span className="mr-1">{activeProduct.currency}</span>
                      {activeProduct.amount.toFixed(2)}
                    </div>
                    <div className={styles.settleText}>
                      {t("fund.rechargeSettleLabel")}: {activeProduct.tokens} {t("common.tokens")} +{" "}
                      {activeProduct!.gift_points} {t("common.points")}
                    </div>
                  </div>
                  <Button className={styles.settleBtn} variant="contained" size="large" onClick={handlePay}>
                    {t("fund.rechargeBuyBtn")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Spinner>
  );
}
