import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Badge, BadgeProps, Button } from "@mui/material";
import { AuthContext } from "@/context";
import * as userApi from "@/api/user";
import * as fundApi from "@/api/fund";
import * as notifyApi from "@/api/notify";
import { ClickableRichTooltip } from "@/components/RichTooltip";
import RechargeDialog from "@/components/Recharge/RechargeDialog";
import UserAvatar from "@/components/UserAvatar";
import { ReactComponent as BCurrencyIcon } from "@/assets/icons/b-currency.svg";

const NotifyBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    right: -26,
    top: 10,
  },
}));

export function UserInfoPopContent(props: { open?: boolean; onRecharge?: () => void }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, setUser, logout } = useContext(AuthContext);
  const [tokens, setTokens] = useState(user?.tokens || 0);
  const [points, setPoints] = useState(user?.points || 0);
  const [notifyCount, setNotifyCount] = useState(0);

  const load = async () => {
    const balance = await fundApi.getBalance();
    setTokens(balance.tokens);
    setPoints(balance.points);
    setUser({
      ...user!,
      tokens: balance.tokens,
      points: balance.points,
    });
  };

  const loadNotifyCount = async () => {
    const { count } = await notifyApi.getUnreadCount();
    setNotifyCount(count);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (props.open) {
      loadNotifyCount();
    }
  }, [props.open]);

  const handleLogout = async () => {
    await userApi.logout();
    logout();
    navigate(0);
  };

  return (
    <div className="pb-6 w-[260px] text-white">
      <Link to={`/profile/${user?.id}`} className="flex items-center p-4">
        <UserAvatar user={user!} />
        <div className="ml-2 truncate">{user!.username}</div>
      </Link>
      <div className="charge px-4 pt-3 flex justify-between items-center">
        <div className="py-2.5 flex">
          <BCurrencyIcon />
          <span className="ml-4 text-base">{tokens} {t("common.tokens")}</span>
        </div>
        <Button
          size="small"
          color="primary"
          variant="contained"
          sx={{
            borderRadius: "30px",
            fontSize: "12px",
            height: "32px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          onClick={props.onRecharge}
        >
          {t("layout.recharge")}
        </Button>
      </div>
      <div className="charge px-4 pt-3 flex justify-between items-center">
        <div className="py-2.5 flex">
          <BCurrencyIcon />
          <span className="ml-4 text-base">{points} {t("common.points")}</span>
        </div>
      </div>
      <ul>
        <li>
          <Link to="/fund/subscribe" className="block leading-5 py-3 px-4 hover:bg-zinc-600">
            {t("layout.subscribeCenter")}
          </Link>
        </li>
        <li>
          <Link to="/fund/recharge" className="block leading-5 py-3 px-4 hover:bg-zinc-600">
            {t("layout.rechargeAndConsumeRecords")}
          </Link>
        </li>
        <li>
          <Link to="/notify" className="block leading-5 py-3 px-4 hover:bg-zinc-600">
            <NotifyBadge badgeContent={notifyCount} color="error">
              <span>{t("layout.notify")}</span>
            </NotifyBadge>
          </Link>
        </li>
        <li>
          <span onClick={handleLogout} className="block cursor-pointer leading-5 py-3 px-4 hover:bg-zinc-600">
            {t("layout.logout")}
          </span>
        </li>
      </ul>
    </div>
  );
}

interface Props {
  children: ReactElement;
  trigger?: "click" | "hover";
}

export default function UserInfoPopover(props: Props) {
  const [rechargeOpen, setRechargeOpen] = useState(false);

  return (
    <>
      <RechargeDialog open={rechargeOpen} onClose={() => setRechargeOpen(false)} />
      <ClickableRichTooltip
        content={(open: boolean) => <UserInfoPopContent open={open} onRecharge={() => setRechargeOpen(true)} />}
        placement="bottom-end"
        trigger={props.trigger || "click"}
        padding={0}
        color="#25262F"
      >
        {props.children}
      </ClickableRichTooltip>
    </>
  );
}
