import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import PageTitle from "@/components/PageTitle";
import { ReactComponent as ArrowIcon } from "@/assets/icons/arrow-left.svg";
import styles from "./StoryEditNav.module.css";

interface Props {
  isEdit?: boolean;
  title: string;
  okText: string;
  onOk: () => void;
  onCancel: () => void;
}

export default function StoryEditNav(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle name="myStories" />
      <header className={styles.header}>
        <div className={"content-wrap " + styles.inner}>
          <Link to="/writing">
            <Button variant="text" color="info" className={styles.back}>
              <ArrowIcon />
            </Button>
          </Link>
          <div className={styles.meta}>
            {!!props.isEdit ? t("writing.editStoryDetails") : t("writing.addStoryInfo")}
          </div>
          <Typography variant="h3" component="h2" className={styles.title}>
            {props.title}
          </Typography>
          <div className={styles.actions}>
            <Button variant="contained" color="info" size="small" className={styles.btn} onClick={props.onCancel}>
              {t("common.cancel")}
            </Button>
            <Button variant="contained" color="primary" size="small" className={styles.btn} onClick={props.onOk}>
              {props.okText}
            </Button>
          </div>
        </div>
      </header>
    </>
  );
}
