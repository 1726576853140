import { createBrowserRouter } from "react-router-dom";
import LoginGuard from "./LoginGuard";
import ReadLimitGuard from "./ReadLimitGuard";
import PortalLayout from "@/layout/PortalLayout";
import BlankLayout from "@/layout/BlankLayout";
import LoginLayout from "@/layout/LoginLayout";
import Home from "@/pages/Home/Home";
import Store from "@/pages/Store/Store";
import Category from "@/pages/StoryList/Category";
import Tag from "@/pages/StoryList/Tag";
import Search from "@/pages/StoryList/Search";
import ReadingPart from "@/pages/Reading/ReadingPart";
import ReadingStory from "@/pages/Reading/ReadingStory";
import NotifyMain from "@/pages/Notify/Main";
import LibraryMain from "@/pages/Library/Main";
import Library from "@/pages/Library/Library";
import BookmarkStory from "@/pages/Library/BookmarkStory";
import BookmarkDetail from "@/pages/Library/BookmarkDetail";
import ReadHistory from "@/pages/Library/ReadHistory";
import Fund from "@/pages/Fund/Fund";
import RechargeFund from "@/pages/Fund/Recharge/Recharge";
import PurchaseFund from "@/pages/Fund/Purchase/Purchase";
import SubscribeFund from "@/pages/Fund/Subscribe/Subscribe";
import RechargeSuccess from "@/pages/Result/RechargeSuccess";
import RechargeError from "@/pages/Result/RechargeError";
import WritingList from "@/pages/Writing/WritingList";
import StoryNew from "@/pages/Writing/StoryNew";
import StoryEdit from "@/pages/Writing/StoryEdit";
import PartEdit from "@/pages/Writing/PartEdit";
import ReadingListList from "@/pages/ReadingList/ReadingListList";
import ReadingList from "@/pages/ReadingList/ReadingList";
import Profile from "@/pages/Profile/Profile";
import Login from "@/pages/Login/Login";
import Signup from "@/pages/Login/Signup";
import Page404 from "@/pages/404";
import Support from "@/pages/Support/Support";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PortalLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "profile/:user_id",
        element: <Profile />,
      },
      {
        path: "notify",
        element: <NotifyMain />,
      },
      {
        path: "category/:category_id?",
        element: <Category />,
      },
      {
        path: "tag/:tag_ids?",
        element: <Tag />,
      },
      {
        path: "search/:search?",
        element: <Search />,
      },
      {
        path: "story/:story_id",
        element: <ReadingStory />,
      },
      {
        path: "readingList/",
        element: <LoginGuard component={ReadingListList} />,
      },
      {
        path: "readingList/:id",
        element: <LoginGuard component={ReadingList} />,
      },
      {
        path: "library",
        element: <LoginGuard component={LibraryMain} />,
        children: [
          {
            path: "",
            element: <Library />,
          },
          {
            path: "bookmark",
            element: <BookmarkStory />,
          },
          {
            path: "bookmark/:story_id",
            element: <BookmarkDetail />,
          },
          {
            path: "history",
            element: <ReadHistory />,
          },
        ],
      },
      {
        path: "fund",
        element: <LoginGuard component={Fund} />,
        children: [
          {
            path: "recharge",
            element: <RechargeFund />,
          },
          {
            path: "purchase",
            element: <PurchaseFund />,
          },
          {
            path: "subscribe",
            element: <SubscribeFund />,
          },
        ],
      },
      {
        path: "result/recharge/success",
        element: <RechargeSuccess />
      },
      {
        path: "result/recharge/error",
        element: <RechargeError />
      },
      {
        path: "writing/",
        element: <LoginGuard component={WritingList} />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "part/",
    element: <BlankLayout />,
    children: [
      {
        element: <ReadLimitGuard component={ReadingPart} />,
        path: ":story_id/:part_id",
      },
    ],
  },
  {
    path: "writing/story/",
    element: <LoginGuard component={BlankLayout} />,
    children: [
      {
        path: "new",
        element: <StoryNew />,
      },
      {
        path: ":story_id",
        element: <StoryEdit />,
      },
      {
        path: ":story_id/part/:part_id",
        element: <PartEdit />,
      },
    ],
  },
  {
    path: "store",
    element: <Store />,
  },
  {
    path: "support",
    element: <BlankLayout />,
    children: [
      {
        path: "",
        element: <Support navigateDefault />,
      },
      {
        path: "*",
        element: <Support />,
      },
    ],
  },
  {
    path: "login/",
    element: <LoginLayout />,
    children: [
      {
        path: "",
        element: <Login />,
      },
    ],
  },
  {
    path: "signup/",
    element: <LoginLayout />,
    children: [
      {
        path: "",
        element: <Signup />,
      },
    ],
  },
  {
    path: "*",
    element: <PortalLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
]);

export default router;
