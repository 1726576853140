export enum Errors {
  Unauthorized = "Unauthorized",
  NotFound = "Not found",
}

export enum Role {
  User = "user",
  Admin = "admin",
}

export enum StoryStatus {
  Completed = 1,
  Ongoing = 2,
}

export enum StoryOrder {
  UpdateTime = "update-desc",
  ReadCount = "read-desc",
}

export enum RechargeMethod {
  Admin = "admin", // 管理员充值
  Stripe = "stripe",
  GooglePlay = "google_play",
}

export enum RechargeCurrency {
  RMB = "CNY", // 人民币
  USDollar = "USD", // 美元
}

// 自动扣费(订阅)状态
export enum SubscribeStoryStatus {
  Off = 0, // 已取消
  On = 1, // 生效中
}

// 通知类型
export enum NotifyType {
  // 系统通知
  System = 0,
  // 评论作者，即作品或章节的一级评论
  CommentAutor = 1,
  // 回复评论，即回复其他评论
  CommentReply = 2,
  // 点赞通知
  Like = 3,
  // 关注通知
  Follow = 4,
}

// 通知状态
export enum NotifyStatus {
  // 未读
  Unread = 0,
  // 已读
  Read = 1,
}
