import { UserEntity } from "@/entity/user";
import $http from "@/utils/http";

export function info(): Promise<null | UserEntity> {
  return $http.request({
    url: "user/info",
    toast: false,
    method: "get",
  });
}

export interface SignupParams {
  email: string;
  verifyCode: string;
  password: string;
  username?: string;
  // birth_year: number;
  // birth_month: number;
  // birth_day: number;
  lang: string;
}
export function signup(data: SignupParams): Promise<UserEntity> {
  return $http.request({
    url: "user/signup",
    method: "post",
    data,
  });
}

export interface SendVerifyCodeParams {
  email: string;
  turnstileToken: string;
}
export function sendSignupVerifyCode(data: SendVerifyCodeParams) {
  return $http.request({
    url: "user/sendSignupVerifyCode",
    method: "post",
    data,
  });
}

export function sendLoginVerifyCode(data: SendVerifyCodeParams) {
  return $http.request({
    url: "user/sendLoginVerifyCode",
    method: "post",
    data,
  });
}

export interface LoginWithPasswordParams {
  email: string;
  password: string;
}
export interface LoginWithVerifyCodeParams {
  email: string;
  verifyCode: string;
}
export type LoginParams = LoginWithPasswordParams | LoginWithVerifyCodeParams;
export function login(data: LoginParams): Promise<UserEntity> {
  return $http.request({
    url: "user/login",
    method: "post",
    data,
  });
}

export function getGoogleOauth2Url() {
  return $http.getUri({
    url: "user/google/oauth2/redirect",
  });
}

export function getFacebookOauth2Url() {
  return $http.getUri({
    url: "user/facebook/oauth2/redirect",
  });
}

export function logout() {
  return $http.request({
    url: "user/logout",
    method: "post",
  });
}

export function changeLanguage(data: { lang: string }) {
  return $http.request({
    url: "user/changeLanguage",
    method: "post",
    data,
  });
}
