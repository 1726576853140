import PageTitle from "@/components/PageTitle";
import FeaturedThisWeek from "./components/FeaturedThisWeek";
import EditorPicks from "./components/EditorPicks";
import WeeklyRecommendations from "./components/WeeklyRecommendations";
import TrendingStories from "./components/TrendingStories";
import PopularTags from "./components/PopularTags";
import NewArrivals from "./components/NewArrivals";
import EditorSelection from "./components/EditorSelection";
import CompletedStories from "./components/CompletedStories";
import MaybeLike from "./components/MaybeLike";

const Home = () => {
  return (
    <>
      <PageTitle name="home" />
      <div className="content-wrap pt-10">
        <div className="flex mb-20">
          <FeaturedThisWeek />
          <EditorPicks className="block ml-12 min-w-0" />
        </div>
        <div className="mb-20">
          <WeeklyRecommendations />
        </div>
        <div className="mb-20">
          <TrendingStories />
        </div>
        <div className="mb-20">
          <PopularTags />
        </div>
        <div className="flex mb-20">
          <NewArrivals />
          <EditorSelection className="ml-16 min-w-0" />
        </div>
      </div>
      <div className="bg-color-default pt-8 pb-8 mb-20">
        <div className="content-wrap">
          <CompletedStories />
        </div>
      </div>
      <div className="content-wrap mb-20">
        <MaybeLike />
      </div>
    </>
  );
};

export default Home;
