import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MenuItem, MenuList } from "@mui/material";
import { AuthContext, GlobalDialogContext } from "@/context";
import { ClickableRichTooltip } from "@/components/RichTooltip";

interface Props {
  children: ReactElement;
}

export default function WritePoppover(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {isLogin} = useContext(AuthContext);
	const {setLoginOpen} = useContext(GlobalDialogContext);

  const handleNewClick = () => {
    if (!isLogin) {
      setLoginOpen(true);
      return;
    }
    navigate("writing/story/new");
  }

  const handleMyStoriesClick = () => {
    if (!isLogin) {
      setLoginOpen(true);
      return;
    }
    navigate("/writing");
  }

  const content = (
    <MenuList>
      <MenuItem onClick={handleNewClick}>{t("layout.nav.newStory")}</MenuItem>
      <MenuItem onClick={handleMyStoriesClick}>{t("layout.nav.myStories")}</MenuItem>
    </MenuList>
  );

  return (
    <ClickableRichTooltip content={content} placement="bottom-start" trigger="hover" padding={0}>
      {props.children}
    </ClickableRichTooltip>
  );
}
