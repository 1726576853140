/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BlockTitle from "./BlockTitle";
import { Typography } from "@mui/material";

interface Props {
  className?: string;
}
export default function EditorPicks(props: Props) {
  const { t } = useTranslation();

  const items = [
    {
      id: "hot",
      cover: "/example/editor-rec.png",
      title: t("home.EcHotTitle"),
      subtitle: t("home.EcHotSubtitle"),
      to: "/category?order=read-desc",
    },
    {
      id: "complete",
      cover: "/example/editor-rec.png",
      title: t("home.EcCompleteTitle"),
      subtitle: t("home.EcCompleteSubtitle"),
      to: "/category?status=1",
    },
    {
      id: "month",
      cover: "/example/editor-rec.png",
      title: t("home.EcMonthTitle"),
      subtitle: t("home.EcMonthSubtitle"),
      to: "/category?order=read-desc",
    },
    {
      id: "new",
      cover: "/example/editor-rec.png",
      title: t("home.EcNewTitle"),
      subtitle: t("home.EcNewSubtitle"),
      to: "/category?order=update-desc",
    },
  ];

  return (
    <div className={props.className}>
      <BlockTitle divider>{t("home.editorPicks")}</BlockTitle>
      <ul
        className="flex"
        css={css`
          margin-left: -12px;
          margin-right: -12px;
        `}
      >
        {items.map((item) => (
          <li
            key={item.id}
            css={css`
              margin: 24px 12px 0;
            `}
          >
            <Link to={item.to}>
              <div
                className="cover"
                css={css`
                  width: 140px;
                  height: 187px;
                  border-radius: 5px;
                  overflow: hidden;
                  &:hover img {
                    transform: scale(1.1);
                  }
                `}
              >
                <img
                  src={item.cover}
                  alt=""
                  css={css`
                    width: 100%;
                    height: 100%;
                    transition: transform 0.3s ease-out;
                  `}
                />
              </div>
              <div className="info">
                <Typography
                  variant="h4"
                  className="title"
                  css={css`
                    margin-top: 14px;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.45;
                  `}
                >
                  {item.title}
                </Typography>
                <div className="subtitle font-color-grey">{item.subtitle}</div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
