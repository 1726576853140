import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RechargeContent from "@/components/Recharge/RechargeContent";
import styles from "./Store.module.css";

export default function Store() {
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Link to="/" className={styles.logo}>
          <img src="/logo.png" alt="" />
        </Link>
        <span>{t("fund.storePageTitle")}</span>
      </div>
      <RechargeContent className={styles.body} />
      <div className={styles.footer}></div>
    </div>
  );
}
