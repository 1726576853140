/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { StoryEntity } from "@/entity/story";
import { formatCover } from "@/utils/format";
import config from "@/config/config";

interface Props {
  items: Partial<StoryEntity>[];
  duration?: number;
}
export default function WeeklyRecommendationsSlider(props: Props) {
  const navigate = useNavigate();
  let { items } = props;
  const duration = props.duration || 3000;
  const timer = useRef<number | null>(null);
  const [active, setActive] = useState(1);
  const activeItem = items[active] || items[0];

  const handleClick = (item: Partial<StoryEntity>) => {
    navigate(`/story/${item.id}`);
  }

  const stopTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  }

  const startTimer = () => {
    stopTimer();
    timer.current = window.setInterval(() => {
      setActive(active => (active + 1) % items.length);
    }, duration);
  }

  const changeActive = (index: number) => {
    setActive(index);
    startTimer();
  };

  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
  }, [items.length]);

  const transformCssArr = [
    css`
      transform: scale(0.8) translate(-160%, -60%);
    `,
    css`
      transform: scale(1) translate(-50%, -50%);
      z-index: 1;
    `,
    css`
      transform: scale(0.8) translate(30%, -60%);
    `,
  ];

  return (
    <div
      css={css`
        width: 355px;
        height: 474px;
        position: relative;
        overflow: hidden;
      `}
      onMouseEnter={stopTimer}
      onMouseLeave={startTimer}
    >
      <div
        css={css`
          width: 100%;
          height: 200px;
          position: relative;
          border-radius: 5px 5px 0 0;
          overflow: hidden;
        `}
      >
        {items.map((item, index) => {
          const transformCss = transformCssArr[(index + 4 - active) % 3];
          return (
            <div
              onClick={() => changeActive(index)}
              key={item.id}
              css={css`
                position: absolute;
                left: 50%;
                top: 50%;
                width: 120px;
                height: 160px;
                cursor: pointer;
                transition-duration: 0.3s;
                border-radius: 4px;
                overflow: hidden;
                &:hover img {
                  transform: scale(1.1);
                }
                ${transformCss}
                ${index === 1 ? "transition-delay: 0.1ms;" : ""}
              `}
            >
              <img
                {...formatCover(item?.cover)}
                alt={item.title}
                loading="lazy"
                css={css`
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  transition: transform 0.3s ease-out;
                `}
              />
            </div>
          );
        })}
        <div
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            z-index: -2;
            width: 100%;
            height: 100%;
            background-size: 389px 518px;
            background-image: url("${activeItem?.cover}"), url("${config.defaultImage}");
            background-repeat: no-repeat;
            background-position: -14px -133px;
            filter: saturate(180%) blur(10px);
            transition: all 0.3s ease-in-out;
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              z-index: -1;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.2);
            }
          `}
        ></div>
      </div>
      <div
        className="desc bg-color-default"
        css={css`
          padding: 25px 24px 28px;
          border-radius: 0 0 5px 5px;
        `}
      >
        <Typography
          variant="h4"
          className="hover:underline"
          onClick={() => handleClick(activeItem)}
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: 1.45,
            padding: "0",
            margin: "0",
            textAlign: "center",
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {activeItem?.title}
        </Typography>
        <div className="mt-2 mb-4 text-center">
          <Link to={`/category/${activeItem?.category?.id}`} className="font-color-grey">{activeItem?.category?.name}</Link>
          <span className="ml-2">{activeItem?.author?.username}</span>
        </div>
        <Typography variant="body2" className="font-color-grey line-clamp-7" sx={{
          textAlign: "justify",
          lineHeight: 1.5,
        }}>
          {activeItem?.description}
        </Typography>
      </div>
    </div>
  );
}
