/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { GlobalDialogContext } from "@/context";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SignupMain from "@/components/Login/SignupMain";

export function SignupDialog() {
  const { signupOpen, setLoginOpen, setSignupOpen } = useContext(GlobalDialogContext);

  const gotoLogin = () => {
    setSignupOpen(false);
    setLoginOpen(true);
  };

  const onSignup = () => {
    setSignupOpen(false);
  };

  const onClose = () => {
    setSignupOpen(false);
  };

  return (
    <Dialog open={signupOpen} onClose={onClose}>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        css={css`
          background: #fff;
          border-radius: 5px;
          padding: 0 40px 40px;
          width: 520px;
        `}
      >
        <SignupMain isDialog gotoLogin={gotoLogin} onSignup={onSignup} />
      </div>
    </Dialog>
  );
}
