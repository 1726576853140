/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { StoryEntity } from "@/entity/story";
import { formatCover } from "@/utils/format";
import config from "@/config/config";
import { useNavigate } from "react-router-dom";

interface Props {
  items: Partial<StoryEntity>[];
  duration?: number;
}
export default function FeaturedThisWeekSlider(props: Props) {
  const navigate = useNavigate();
  const { items } = props;
  const duration = props.duration || 3000;
  const timer = useRef<number | null>(null);
  const [active, setActive] = useState(0);

  const handleClick = (item: Partial<StoryEntity>) => {
    navigate(`/story/${item.id}`);
  };

  const stopTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const startTimer = () => {
    stopTimer();
    timer.current = window.setInterval(() => {
      setActive((active) => (active + 1) % items.length);
    }, duration);
  };

  const changeActive = (index: number) => {
    setActive(index);
    startTimer();
  };

  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
  }, [items.length]);

  return (
    <div
      css={css`
        width: 480px;
        height: 288px;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
      `}
      onMouseEnter={stopTimer}
      onMouseLeave={startTimer}
    >
      {items.map((item, index) => {
        const activeCss =
          index === active
            ? css`
                visibility: visible;
                opacity: 1;
                transform: scale(1);
                transition-delay: 0ms;
                z-index: 2;
              `
            : "";

        return (
          <div
            onClick={() => handleClick(item)}
            key={item.id}
            css={css`
              position: absolute;
              width: 480px;
              height: 100%;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: center;
              padding: 36px 24px 0;
              transition-duration: 200ms;
              transform: scale(1.1);
              visibility: hidden;
              opacity: 0;
              ${activeCss}
            `}
          >
            <div
              css={css`
                position: absolute;
                left: 0;
                top: 0;
                z-index: -2;
                width: 100%;
                height: 100%;
                background-size: 526px 746px;
                background-image: url("${item.cover}"), url("${config.defaultImage}");
                background-repeat: no-repeat;
                background-position: -19px -217px;
                filter: saturate(180%) blur(10px);
                transition: all 0.3s ease-in-out;
                &:before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: -1;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.2);
                }
              `}
            ></div>
            <div
              css={css`
                width: 150px;
                height: 200px;
                overflow: hidden;
                border-radius: 4px;
                &:hover img {
                  transform: scale(1.1);
                }
              `}
            >
              <img
                {...formatCover(item.cover)}
                alt={item.title}
                css={css`
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  transition: transform 0.3s ease-out;
                `}
              />
            </div>
            <div className="ml-4 min-w-0 flex-1">
              <Typography
                variant="h4"
                className="hover:underline"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  lineHeight: 1.45,
                  padding: "0",
                  marginTop: "1.5rem",
                  marginBottom: "1.5rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  color: "#fff",
                  cursor: "pointer",
                  textShadow: "1px 1px rgba(0, 0, 0, 0.3)",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body1"
                className="line-clamp-4"
                sx={{
                  fontSize: "1rem",
                  fontWeight: 400,
                  lineHeight: 1.45,
                  color: "#fff",
                  textShadow: "1px 1px rgba(0, 0, 0, 0.3)",
                  cursor: "pointer",
                }}
              >
                {item.description}
              </Typography>
            </div>
          </div>
        );
      })}
      <div
        className="dots"
        css={css`
          position: absolute;
          z-index: 2;
          bottom: 17px;
          left: 50%;
          transform: translateX(-50%);
        `}
      >
        {items.map((item, index) => (
          <span
            key={item.id}
            onClick={() => changeActive(index)}
            css={css`
              display: inline-block;
              cursor: pointer;
              padding: 4px;
            `}
          >
            <i
              css={css`
                display: block;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background-color: ${active === index ? "#fff" : "rgba(255, 255, 255, 0.4)"};
              `}
            ></i>
          </span>
        ))}
      </div>
    </div>
  );
}
