import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Turnstile, { useTurnstile } from "react-turnstile";
import config from "@/config/config";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, OutlinedInput } from "@mui/material";
import * as userApi from "@/api/user";
import { AuthContext } from "@/context";
import Spinner from "@/components/Spinner";
import Alert from "./Alert";
import LoginFooter from "./LoginFooter";

interface Props {
  onLogin: () => void;
  gotoSignup: () => void;
}
export default function LoginVerifyCodeForm(props: Props) {
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const timer = useRef<number | null>(null);
  const [countdown, setCountdown] = useState(0);

  const turnstile = useTurnstile();
  const [turnstileToken, setTurnstileToken] = useState("");

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      email: "",
      verifyCode: "",
    },
  });

  const stopCountdownTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  const startCountdownTimer = () => {
    stopCountdownTimer();
    setCountdown(60);
    timer.current = window.setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          stopCountdownTimer();
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1e3);
  };

  const handleVerifyCode = async () => {
    const email = getValues("email");
    if (!turnstileToken) {
      setMessage(t("login.turnstileRequired")!);
      return;
    }

    if (email && !errors.email) {
      startCountdownTimer();
      await userApi.sendLoginVerifyCode({
        email,
        turnstileToken,
      });
      turnstile.reset();
    }
  };

  useEffect(() => {
    return () => {
      stopCountdownTimer();
    };
  }, []);

  const onSubmit = async (params: any) => {
    try {
      setLoading(true);
      const user = await userApi.login({
        email: params.email,
        verifyCode: params.verifyCode,
      });
      await setUser(user);
      props.onLogin();
    } catch (err: any) {
      setMessage(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spinner loading={loading}>
      <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Alert open={!!message} message={message} onClose={() => setMessage("")} className="mt-6 w-full" duration={3} />
        <div className="mt-6 w-full">
          <Controller
            name="email"
            control={control}
            rules={{
              required: t("login.emailRequired") as string,
              pattern: { value: /^\S+@\S+$/i, message: t("login.emailInvalid") as string },
            }}
            render={({ field }) => (
              <FormControl error={!!errors.email} fullWidth>
                <OutlinedInput id="email" placeholder={t("login.email")!} {...field} sx={{ background: "#fff" }} />
                {errors.email && (
                  <FormHelperText error={!!errors.email}>{errors.email.message! as string}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </div>
        <div className="mt-6 flex">
          <Turnstile
            css={{ transform: "scale(0.96)", transformOrigin: "left" }}
            sitekey={config.turnstileSiteKey}
            onVerify={(token) => {
              setTurnstileToken(token);
            }}
          />
        </div>
        <div className="mt-6 w-full flex justify-between">
          <Controller
            name="verifyCode"
            control={control}
            rules={{
              required: t("login.verifyCodeRequired") as string,
            }}
            render={({ field }) => (
              <FormControl error={!!errors.verifyCode} className="flex-1">
                <OutlinedInput
                  id="verifyCode"
                  placeholder={t("login.verifyCode")!}
                  sx={{ background: "#fff" }}
                  {...field}
                />
                {errors.verifyCode && (
                  <FormHelperText error={!!errors.verifyCode}>{errors.verifyCode.message! as string}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <div>
            <Button
              variant={!getValues("email") || !!errors.email || !!countdown ? "light" : "contained"}
              sx={{
                marginLeft: "1.5rem",
                height: "56px",
                width: "128px",
              }}
              disabled={!getValues("email") || !!errors.email || !!countdown}
              onClick={handleVerifyCode}
            >
              {countdown ? countdown + "S" : t("login.getVerifyCode")}
            </Button>
          </div>
        </div>
        <div className="mt-2 flex justify-between items-center font-color-grey">
          <FormControlLabel
            control={<Checkbox sx={{ color: "var(--border-color-highlight)" }} />}
            label={t("login.autoLogin")}
          />
          <a href="/login/forget">{t("login.forgotPassword")}</a>
        </div>
        <div className="mt-7">
          <Button type="submit" fullWidth variant="contained" /* disabled={!isValid} */ sx={{ height: "56px" }}>
            {t("common.login")}
          </Button>
        </div>
        <LoginFooter gotoSignup={props.gotoSignup} />
      </Box>
    </Spinner>
  );
}
