/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import BlockTitle from "./BlockTitle";
import { StoryEntity } from "@/entity/story";
import * as homeApi from "@/api/home";
import { formatCover } from "@/utils/format";

export default function EditorSelection(props: { className?: string }) {
  const className = props.className || "";
  const { t } = useTranslation();
  const [items, setItems] = useState<StoryEntity[]>([]);

  const load = () => {
    homeApi.editorsSelection().then((res) => {
      setItems(res.items);
    });
  };

  useEffect(() => {
    load();
  }, []);

  const switchList = () => {
    load();
  };

  return (
    <div className={className + " editor-picks"}>
      <BlockTitle divider actionText={t("home.switch")!} onActionClick={switchList}>
        {t("home.editorSelection")}
      </BlockTitle>
      <ul className="clearfix -ml-6 -mt-2">
        {items.map((item) => (
          <li key={item.id} className="float-left w-1/2 pl-6 mt-6">
            <div className="flex">
              <Link
                to={`/story/${item.id}`}
                css={css`
                  display: block;
                  width: 54px;
                  height: 72px;
                  border-radius: 3px;
                  overflow: hidden;
                  &:hover img {
                    transform: scale(1.1);
                  }
                `}
              >
                <img
                  {...formatCover(item.cover)}
                  alt={item.title}
                  loading="lazy"
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transition: transform 0.3s ease-out;
                  `}
                />
              </Link>
              <div className="flex-1 ml-4 min-w-0">
                <Link to={`/story/${item.id}`}>
                  <Typography
                    variant="h4"
                    className="truncate"
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      margin: "0",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
                <Link to={`/category/${item.category?.id}`}>
                  <Typography variant="body2" sx={{ fontSize: "12px", marginTop: "6px" }}>
                    {item.category?.name}
                  </Typography>
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
