/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { GlobalDialogContext } from "@/context";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoginMain from "@/components/Login/LoginMain";

export function LoginDialog() {
  const { loginOpen, setLoginOpen, setSignupOpen } = useContext(GlobalDialogContext);

  const gotoSignup = () => {
    setLoginOpen(false);
    setSignupOpen(true);
  };

  const onLogin = () => {
    setLoginOpen(false);
  };

	const onClose = () => {
		setLoginOpen(false);
	}

  return (
    <Dialog open={loginOpen} onClose={onClose}>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        css={css`
          background: #fff;
          border-radius: 5px;
          padding: 0 40px 40px;
          width: 520px;
        `}
      >
        <LoginMain isDialog gotoSignup={gotoSignup} onLogin={onLogin} />
      </div>
    </Dialog>
  );
}
