import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import FooterText from "./FooterText";

interface Props {
  gotoSignup: () => void;
}
export default function LoginFooter(props: Props) {
  const { t } = useTranslation();

  const handleClick = (evt: MouseEvent) => {
    evt.preventDefault();
    props.gotoSignup();
  };

  return (
    <>
      <div className="text-center" style={{ marginTop: "130px" }}>
        <a href="/signup" className="font-color-primary" onClick={handleClick}>
          <span className="font-color-grey mr-2">{t("login.noAccount")}</span>
          {t("common.signup")}
        </a>
      </div>
      <FooterText />
    </>
  );
}
