import { useNavigate } from "react-router-dom";
import { useNavigateLogin } from "@/hooks";
import LoginMain from "@/components/Login/LoginMain";

export default function Login() {
  const { navigateBack } = useNavigateLogin();
	const navigate = useNavigate();
  const {backPath: redirect} = useNavigateLogin();

	
	const gotoSignup = () => {
		let link = "/signup";
		if (redirect) {
			link += "?redirect=" + encodeURIComponent(redirect);
		}
		navigate(link);
	}


  return (
		<LoginMain isDialog={false} onBack={navigateBack} gotoSignup={gotoSignup} onLogin={navigateBack} />
	)
}
