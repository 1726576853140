/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import BlockTitle from "./BlockTitle";
import { StoryEntity } from "@/entity/story";
import * as homeApi from "@/api/home";
import { formatCover } from "@/utils/format";

export default function CompletedStories(props: { className?: string }) {
  const className = props.className || "";
  const { t } = useTranslation();
  const [items, setItems] = useState<StoryEntity[]>([]);

  const load = () => {
    homeApi.finishedStories().then((res) => {
      setItems(res.items);
    });
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <div className={className + " completed-stories"}>
      <BlockTitle divider>{t("home.completedStories")}</BlockTitle>
      <ul className="flex -mx-4 mt-4">
        {items.map(item => (
          <li key={item.id} className="flex-1 mx-4">
            <Link to={`/story/${item.id}`} css={css`
                display: block;
                border-radius: 5px;
                overflow: hidden;
                &:hover img {
                  transform: scale(1.1);
                }
              `}>
              <img
                {...formatCover(item.cover)}
                loading="lazy"
                alt={item.title}
                css={css`
                  width: 100%;
                  transition: transform 0.3s ease-out;
                `}
              />
            </Link>
            <Link to={`/story/${item.id}`} className="block mt-2 mb-1">
              <Typography
                variant="h4"
								className="line-clamp-2"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  margin: "0",
                }}
              >
                {item.title}
              </Typography>
            </Link>
            <Link to={`/category/${item.category?.id}`}>
              <Typography variant="body2" sx={{ fontSize: "14px" }}>
                {item.category?.name}
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
