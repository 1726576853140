import { ComponentProps, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import Nav from "./components/Nav";
import Header from "./components/Header";
import * as pageApi from "@/api/page";
import Spinner from "@/components/Spinner";
import { PageEntity } from "@/entity/page";
import PageTitle from "@/components/PageTitle";
import Typography from "@mui/material/Typography";
import Footer from "@/layout/components/Footer";
import Page404 from "../404";

export default function PortalLayout(props: { navigateDefault?: boolean }) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language;
  const [pages, setPages] = useState([] as ComponentProps<typeof Nav>["items"]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState<PageEntity>();
  const { pathname } = useLocation();

  const activePageId = pages.find((page) => matchPath(page.path, pathname))?.id;

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const data = await pageApi.list({
          lang,
        });
        setPages(
          data.items.map((item) => ({
            id: item.id,
            title: item.title,
            key: item.key,
            path: "/support/" + item.key.toLowerCase(),
          }))
        );
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [lang]);

  useEffect(() => {
    if (props.navigateDefault && pages.length) {
      navigate(pages[0].path);
    }
  }, [props, pages]);

  useEffect(() => {
    const load = async (id: number) => {
      try {
        setLoading(true);
        const data = await pageApi.detail({
          id,
        });
        setActivePage(data);
      } finally {
        setLoading(false);
      }
    };

    if (activePageId) {
      load(activePageId);
    }
  }, [activePageId]);

  return (
    <div className="flex flex-col min-h-screen">
      <PageTitle title={activePage?.title} />
      <Header />
      <div className="pt-4 mb-8 content-wrap flex flex-1">
        <Nav items={pages} />
        <div className="flex-1">
          <Spinner loading={loading}>
            <div className="min-h-[200px]">
              {!loading && !activePage ? (
                <Page404 />
              ) : (
                <div className="pt-4">
                  <Typography variant="h5" color="initial">
                    {activePage?.title}
                  </Typography>
                  <pre
                    className="mt-6"
                    style={{
                      fontSize: "18px",
                      lineHeight: "26px",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {activePage?.content}
                  </pre>
                </div>
              )}
            </div>
          </Spinner>
        </div>
      </div>
      <Footer />
    </div>
  );
}
