import React, { useState } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import router from "./router/router";
import { ToastContainer } from "react-toastify";
import { ConfirmProvider } from "@/components/Confirm";
import "react-toastify/dist/ReactToastify.css";
import { PrivateUserEntity } from "@/entity/user";
import { useStateCallback } from "./hooks/useStateCallback";
import { AuthContext, GlobalDialogContext } from "@/context";
import { LoginDialog } from "./components/Login/LoginDialog";
import { SignupDialog } from "./components/Login/SignupDialog";
import { theme } from "./theme";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./app.css";
import "./twind.css";

interface Props {
  user: PrivateUserEntity | null;
}
export default function App(props: Props) {
  const [user, setUser] = useStateCallback<PrivateUserEntity | null>(props.user);
  const authCtx = {
    user,
    isLogin: !!user,
    setUser: (u: null | PrivateUserEntity) => {
      return new Promise((resolve) => {
        setUser(u, () => {
          resolve(u);
        });
      });
    },
    logout: () => {
      return new Promise((resolve) => {
        setUser(null, () => {
          resolve(null);
        });
      });
    },
  };

  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const globalDialogCtx = {
    loginOpen,
    setLoginOpen,
    signupOpen,
    setSignupOpen,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="colored"
        />
        <ConfirmProvider>
          <AuthContext.Provider value={authCtx}>
            <GlobalDialogContext.Provider value={globalDialogCtx}>
              <LoginDialog />
              <SignupDialog />
              <RouterProvider router={router} />
            </GlobalDialogContext.Provider>
          </AuthContext.Provider>
        </ConfirmProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}
