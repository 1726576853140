import { useNavigate } from "react-router-dom";
import { useNavigateLogin } from "@/hooks";
import SignupMain from "@/components/Login/SignupMain";

export default function SignUp() {
  const { navigateBack } = useNavigateLogin();
	const navigate = useNavigate();
  const {backPath: redirect} = useNavigateLogin();

	
	const gotoLogin = () => {
		let link = "/login";
		if (redirect) {
			link += "?redirect=" + encodeURIComponent(redirect);
		}
		navigate(link);
	}


  return (
		<SignupMain isDialog={false} onBack={navigateBack} gotoLogin={gotoLogin} onSignup={navigateBack} />
	)
}
