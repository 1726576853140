import { forwardRef, useContext } from "react";
import { AuthContext, GlobalDialogContext } from "@/context";
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";

const LinkNeedLogin = forwardRef((props: LinkProps) => {
  const { isLogin } = useContext(AuthContext);
  const { setLoginOpen } = useContext(GlobalDialogContext);

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (!isLogin) {
      event.preventDefault();
      setLoginOpen(true);
    }
  }

  return <Link onClick={handleClick} {...props} />;
});
export { LinkNeedLogin };

const NavLinkNeedLogin = forwardRef((props: NavLinkProps, ref: any) => {
  const { isLogin } = useContext(AuthContext);
  const { setLoginOpen } = useContext(GlobalDialogContext);

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (!isLogin) {
      event.preventDefault();
      setLoginOpen(true);
    }
  }

  return <NavLink ref={ref} onClick={handleClick} {...props} />;
});
export { NavLinkNeedLogin };
