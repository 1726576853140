import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  open: boolean;
  onClose?: () => void;
  storyTitle?: string;
  partTitle?: string;
  onOk?: () => void;
}
export default function NotEnoughDialog(props: Props) {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle align="center" sx={{ paddingTop: "40px", fontSize: "24px" }}>
        {t("fund.notEnoughtDialogTitle")}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: 480, padding: "20px 48px 40px" }}>
        <div className="px-8">

          <Typography
            variant="body1"
            sx={{
              marginBottom: "40px",
              color: "#D40000",
            }}
          >
            {t("fund.notEnoughtDialogDesc")}
          </Typography>
          <Typography
            variant="body2"
            className="font-color-grey text-center"
            sx={{
              marginBottom: "8px",
            }}
          >
            {t("common.chapter")}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "40px",
            }}
          >
            {props.storyTitle}: {props.partTitle}
          </Typography>
        </div>
        <Button variant="contained" size="large" sx={{ width: "100%", height: "56px" }} onClick={props.onOk}>
          {t("fund.gotoRecharge")}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
